import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import EditEducationEditor from 'components/ContentAdmin/EducationTab/EducationList/EditEducationEditor'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    button: {
      marginTop: theme.spacing(3),
    },
  })
)

export default () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.root}>
        <EditEducationEditor />

        {/*<Grid container spacing={3}>*/}
        {/*  <Grid item xs={9}>*/}
        {/*    <EditEducationEditor />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={3}>*/}
        {/*    /!*<AiTable />*!/*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </div>
    </Layout>
  )
}

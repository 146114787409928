import React from 'react'
import Loadable from 'react-loadable'

const EditEducationEditor = Loadable({
  // loader: () => import('./Froala'),
  loader: () => import('./EditEducationQuillEditor'),
  loading() {
    return 'Loading...'
  },
})
export default () => <EditEducationEditor />
